// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-forsight-js": () => import("./../../../src/pages/apps/forsight.js" /* webpackChunkName: "component---src-pages-apps-forsight-js" */),
  "component---src-pages-connect-contact-us-js": () => import("./../../../src/pages/connect/contact-us.js" /* webpackChunkName: "component---src-pages-connect-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-pricing-plans-js": () => import("./../../../src/pages/pricing/plans.js" /* webpackChunkName: "component---src-pages-pricing-plans-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-story-purpose-js": () => import("./../../../src/pages/story/purpose.js" /* webpackChunkName: "component---src-pages-story-purpose-js" */),
  "component---src-pages-story-team-js": () => import("./../../../src/pages/story/team.js" /* webpackChunkName: "component---src-pages-story-team-js" */),
  "component---src-pages-uses-builder-js": () => import("./../../../src/pages/uses/builder.js" /* webpackChunkName: "component---src-pages-uses-builder-js" */),
  "component---src-pages-uses-clinician-js": () => import("./../../../src/pages/uses/clinician.js" /* webpackChunkName: "component---src-pages-uses-clinician-js" */),
  "component---src-pages-uses-finance-js": () => import("./../../../src/pages/uses/finance.js" /* webpackChunkName: "component---src-pages-uses-finance-js" */),
  "component---src-pages-uses-planner-designer-js": () => import("./../../../src/pages/uses/planner-designer.js" /* webpackChunkName: "component---src-pages-uses-planner-designer-js" */),
  "component---src-pages-uses-strategy-js": () => import("./../../../src/pages/uses/strategy.js" /* webpackChunkName: "component---src-pages-uses-strategy-js" */)
}

