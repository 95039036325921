import React from 'react';

// import { faEnvelope as farEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faEnvelope, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { fab } from "@fortawesome/free-brands-svg-icons"
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faEnvelope, faArrowRight, faArrowLeft, fab)


const STORE = {
    isMobile: undefined
}

const StoreContext = React.createContext(STORE);

function StoreProvider(props) {

    const [store, setStore] = React.useState(STORE)

    React.useEffect(() => {})
        

    React.useEffect(() => {
        let isMobile = undefined
        if (typeof window === `undefined`) return 

        const innherHeight = window.innerHeight
        const contentMinHeight = innherHeight*.75
        const footerMinHeight = innherHeight - contentMinHeight

        function checkSize() {
            isMobile = window.matchMedia("only screen and (max-width: 760px)").matches || navigator.userAgent.indexOf("Mobi") > -1;
            setStore(prev => (
                    { ...prev, ...{ isMobile, contentMinHeight, footerMinHeight, innherHeight} }
            ))

        }
        checkSize()
        window.addEventListener('resize', checkSize);

        return () => window.removeEventListener('resize', checkSize);
    }, [])

    return (
        typeof store.isMobile !== 'undefined' && 
        <StoreContext.Provider value={store}>
            {props.children}
        </StoreContext.Provider>
    )


}

export { StoreContext, StoreProvider };
